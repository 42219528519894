import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Input } from '@openbox-app-shared'
import dashboardIcons from '../icons'
import HeaderBar from './HeaderBar'

const StyledBox = styled.section`
  padding: 1rem;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(60, 68, 86, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  &.is-dark-mode {
    background-color: #1A1F35;
    .component--h2, p, .component--input > * {
      color: white;
    }
    .component--list {
      background-color: white;
    }
    .component--button.is-secondary {
      background-color: #282c34;
      border-color: #282c34;
      color: white;
    }
  }
  &.is-developer-mode {
    position: relative;
    background-size: 100% 100%;
    background-image: url(/developer.jpg);
    > * {
      z-index: 1;
      position: relative;
    }
    .component--header-bar {
      padding-left: 0.5rem;
      > * {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    .component--input > * {
      background-color: transparent;
      border: 0;
      padding: 0 0.5rem 0 0.5rem;
    }
  }
  &.is-developer-mode:after {
    position: absolute;
    top: 6px; bottom: 6px;
    left: 6px; right: 6px;
    background-color: white;
    content: '';
    border-radius: 4px;
  }
  &.is-dark-mode.is-developer-mode:after {
    background-color: #1A1F35;
  }
`

function Box ({ className, isDarkMode, developerModeHeader, developerModeCode, children, ...props }) {
  return (
    <StyledBox className={classnames('component--box', { 'is-dark-mode': isDarkMode, 'is-developer-mode': (developerModeHeader || developerModeCode) }, className)} {...props}>
      {developerModeHeader && <HeaderBar text={developerModeHeader} Icon={dashboardIcons.developer} />}
      {developerModeCode && <Input type='code' value={developerModeCode} />}
      {children}
    </StyledBox>
  )
}

Box.propTypes = {
  className: PropTypes.string,
  isDarkMode: PropTypes.bool,
  developerModeHeader: PropTypes.string,
  developerModeCode: PropTypes.string,
  children: PropTypes.node
}

export default Box
