import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import UserCircle from './UserCircle'

const StyledComponent = styled.div`
  height: 3rem;
  cursor: default;
  > .component--user-circle {
    float: left;
  }
  > .more {
    height: 3rem;
    position: relative;
    padding-top: 1rem;
    padding-left: 4rem;
    color: #1A1F35;
    .component--tags {
      margin-top: 0.4rem;
    }
  }
`

export default function FederatedUser({ federatedUser }) {
  return <StyledComponent className='component--federated-user'>
    <UserCircle fixedWidthHeight={3} name={federatedUser.name} color={federatedUser.color} photoUrl={federatedUser.photoUrl} />
    <div className='more'>
      <strong>{federatedUser.name}</strong>
      {/* <FederatedUserRoles key='d--roles' roles={federatedUser.roles} color='#90a4ae' /> */}
    </div>
  </StyledComponent>
}
FederatedUser.propTypes = {
  federatedUser: PropTypes.object
}
