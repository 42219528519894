/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import dashboardIcons from '../../icons'
import { dispatch, subscribe } from '../../redux'
import { icons, Button, Tags, Tag } from '@openbox-app-shared'
import ScrollableTable from '../../components/ScrollableTable'
import H1 from '../../components/H1'

import { CustomHelmet, Loading, Time } from '@openbox-app-shared'

const StyledComponent = styled.div`
  padding-top: 1rem;
  .component--button + .component--h1,
  .component--scrollable-table + .component--scrollable-table, .component--scrollable-table + .component--h1
  {
    margin-top: 1rem;
  }
  .component--h1 + .component--scrollable-table {
    margin-top: 0.5rem;
  }
  .component--scrollable-table {
    td:nth-child(1) {
      min-width: 8rem; max-width: 8rem; width: 8rem;
    }
    td:nth-child(2) {
      min-width: 8rem; max-width: 8rem; width: 8rem;
    }
    td:nth-child(3) {
      min-width: 12rem; max-width: 12rem; width: 12rem;
    }
    td:nth-child(4) {
      min-width: 12rem;
    }
  }
`

const COLUMNS = {
  id: '',
  toString: '',
  createdAt: 'Created',
  executedAt: 'Last used',
  actions: ''
}

function MyList ({ user, executableCodes }) {
  const rows = executableCodes.map(_ => {
    const tags = []
    _.session && tags.push(<Tag
      tag={{
        name: _.session.identifierUnique,
        InlineIcon: dashboardIcons.teamMember,
        onClick: () => {
          dispatch('LOADING')
          window.sticky.session.get(undefined, false, _.lastExecutedSessionId)
            .then(session => {
              dispatch('SESSION', { session })
              dispatch('STOP_LOADING')
            })
            .catch(({ message }) => {
              window.sticky.applications.blocks.showError(message, true)
            })
            .then(() => {
              dispatch('STOP_LOADING')
            })
        }
      }}
    />)
    return {
      id: <strong style={{ color: _.constructor.color }}><code>{_.code}</code></strong>,
      toString: _.toString(user.currency),
      createdAt: <Time time={_.createdAt} />,
      executedAt: _.isExecuted ? <><Tags>{tags}</Tags><Time time={_.executedAt} /></> : '---',
      actions: (<>
        <Button
          title='Delete'
          backgroundColor='#ff3838'
          InlineIcon={dashboardIcons.delete}
          onClick={() => {
            dispatch('SURE_DELETE', { why: 'executableCode', entity: _ })
          }}
        />
      </>)
    }
  })
  return (
    <ScrollableTable
      rows={rows}
      columns={COLUMNS}
    />
  )
  // name: _.isExecuted ? 'Used' : 'Not used',
  // color:  _.isExecuted ? '#ff3838' : '#40cf7a'
}

function RenderChild ({ context }) {
  const { user } = context.props
  const [lastQuantumBalance, setLastQuantumBalance] = useState(2500)
  const [lastQuantumDiscount, setLastQuantumDiscount] = useState(10)
  const [executableCodes, setExecutablesCodes] = useState()

  async function getExecutableCodes (force = false) {
    const _ = (await window.sticky.executableCodes.getAll(force))
    setExecutablesCodes(_)
  }

  useEffect(
    () => {
      const subscriptions = [
        subscribe(
          'SURE_DELETE_GOOD',
          ({ why, entity }) => {
            why === 'executableCode' && (() => {
              ;(async () => {
                try {
                  await window.sticky.executableCodes.remove(entity)
                  await getExecutableCodes()
                } catch (e) {
                  dispatch('SHOW_MESSAGE', { message: <p>{e.message}</p>, canBeBadded: '' })
                }
              })()
            })()
          }
        )
      ]
      ;(async () => {
        try {
          await getExecutableCodes()
        } catch (e) {
          dispatch('SHOW_MESSAGE', { message: <p>{e.message}</p>, canBeBadded: '' })
        }
      })()
      return () => {
        subscriptions.forEach(s => s())
      }
    },
    []
  )

  async function ecDone (type, code, value) {
    dispatch('LOADING')
    await window.sticky.executableCodes.create({ code, type, configuration: { originalQuantum: value, quantum: value } })
    await getExecutableCodes(true)
    dispatch('STOP_LOADING')
  }

  return (
    <StyledComponent>
      <CustomHelmet title={`Codes | Account | ${user.name}`} />
      <div className='buttons'>
        <Button
          icon={icons.inverted.add}
          backgroundColor='#218c74'
          onClick={() => {
            ;(async () => {
              const k = `How much (${user.currency})?`
              const { [k]: value, 'Code (Leave empty for random)': code } = await window.sticky.applications.blocks.renderInlineEventsButton(
                [
                  {
                    'id': '0e1f0565-5e05-471c-b855-bbe44c20527d',
                    'config': {
                      'label': k,
                      'type': 'Price',
                      'value': parseInt(lastQuantumBalance / 100, 10).toFixed(2)
                    }
                  },
                  {
                    'id': '0e1f0565-5e05-471c-b855-bbe44c20527d',
                    'config': {
                      'label': 'Code (Leave empty for random)',
                      'type': 'Text',
                      'value': ''
                    }
                  }
                ],
                'Add'
              )
              const finalValue = Math.floor(parseInt(value, 10) * 100)
              await ecDone('StickyBalance', code, finalValue)
              setLastQuantumBalance(finalValue)
            })()
          }}
        >
          Top up
        </Button>
        <Button
          icon={icons.inverted.add}
          backgroundColor='#fd7272'
          onClick={() => {
            ;(async () => {
              const k = 'How much (%)?'
              const { [k]: value, 'Code (Leave empty for random)': code } = await window.sticky.applications.blocks.renderInlineEventsButton(
                [
                  {
                    'id': '0e1f0565-5e05-471c-b855-bbe44c20527d',
                    'config': {
                      'label': k,
                      'type': 'Number',
                      'value': lastQuantumDiscount.toString()
                    }
                  },
                  {
                    'id': '0e1f0565-5e05-471c-b855-bbe44c20527d',
                    'config': {
                      'label': 'Code (Leave empty for random)',
                      'type': 'Text',
                      'value': ''
                    }
                  }
                ],
                'Add'
              )
              const finalValue = Math.floor(value)
              await ecDone('StickyDiscount', code, finalValue)
              setLastQuantumDiscount(finalValue)
            })()
          }}
        >
          Permanent discount
        </Button>
      </div>
      {!executableCodes && (
        <Loading />
      )}
      {executableCodes && <>
        <H1>Used</H1>
        <MyList user={user} executableCodes={executableCodes.filter(_ => _.isExecuted)} />
        <H1>Not used</H1>
        <MyList user={user} executableCodes={executableCodes.filter(_ => !_.isExecuted)} />
      </>}
    </StyledComponent>
  )
}

const tab = {
  id: 'executable-codes',
  name: (context) => 'Codes',
  inlineIcon: dashboardIcons.executableCode,
  to: (context) => '/my/account/executable-codes',
  child: props => <RenderChild {...props} />
}

export default tab
