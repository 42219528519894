import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styled from 'styled-components'

import { Input, Dropdown, Button } from '@openbox-app-shared'
import ScrollableTable from '../../../../../components/ScrollableTable'
import H2 from '../../../../../components/H2'
import dashboardIcons from '../../../../../icons'

const StyledComponent = styled.div`
  .component--h2 {
    margin-bottom: 0.5rem;
  }
  .component--dropdown select {
    width: 100%;
    max-width: unset;
  }
  .component--table td:nth-child(5) {
    min-width: calc(1.75rem + 1.5rem);
    max-width: calc(1.75rem + 1.5rem);
    width: calc(1.75rem + 1.5rem);
    .component--button {
      margin-top: 0.25rem;
    }
  }
`

const COLUMNS = {
  name: 'Name',
  key: 'Key',
  type: 'Type',
  defaultValue: 'Default value',
  actions: ''
}

const TYPES = [
  {
    id: 'boolean',
    name: 'Switch (Yes/No)',
    defaultValue: true
  },
  {
    id: 'number',
    name: 'Number',
    defaultValue: 100
  },
  {
    id: 'string',
    name: 'Text',
    defaultValue: ''
  },
  {
    id: 'string-multiline',
    name: 'Text (multiple lines)',
    defaultValue: ''
  },
  {
    id: '---1',
    name: '──────────',
    disabled: true
  },
  {
    id: 'thing',
    name: 'Sticky (sticker)',
    defaultValue: ''
  },
  {
    id: 'application',
    name: 'Flow',
    defaultValue: ''
  },
  {
    id: 'application--none',
    name: 'Flow (optional)',
    defaultValue: ''
  },
  {
    id: 'application--none-todo',
    name: 'Flow (optional, create a to do)',
    defaultValue: ''
  },
  {
    id: 'application--all',
    name: 'Flow (any)',
    defaultValue: ''
  },
  {
    id: 'product',
    name: 'Product',
    defaultValue: ''
  },
  {
    id: 'product-optional',
    name: 'Product (optional)',
    defaultValue: ''
  },
  {
    id: 'product-category',
    name: 'Product category',
    defaultValue: ''
  },
  {
    id: 'product-categories',
    name: 'Product categories',
    defaultValue: []
  },
  {
    id: 'federated-user',
    name: 'Team member',
    defaultValue: ''
  },
  {
    id: '---2',
    name: '──────────',
    disabled: true
  },
  {
    id: 'time',
    name: 'Time',
    defaultValue: 12 * 60
  },
  {
    id: 'time--no-limit',
    name: 'Time (no limit)',
    defaultValue: 60
  },
  {
    id: 'code',
    name: 'Code',
    defaultValue: ''
  },


  {
    id: 'date',
    name: 'Date'
  },
  {
    id: 'price',
    name: 'Price',
    defaultValue: 100
  },
  {
    id: 'url',
    name: 'URL'
  },
  {
    id: 'url--optional',
    name: 'URL (optional)'
  },
  {
    id: 'uploadImage',
    name: 'Upload image'
  },
  {
    id: 'uploadVideo',
    name: 'Upload video'
  },
  {
    id: 'uploadZippedVideo',
    name: 'Upload zipped video'
  },
  {
    id: 'uploadDocument',
    name: 'Upload document'
  },
  {
    id: 'uploadImage--choose',
    name: 'Upload image (choice)'
  },
  {
    id: 'uploadAudio',
    name: 'Upload audio'
  },
  {
    id: 'options',
    name: 'Options'
  },
  {
    id: 'array',
    name: 'List'
  },
  {
    id: 'colour',
    name: 'Color'
  },
  {
    id: 'icon',
    name: 'Icon'
  },
  {
    id: 'language--phone',
    name: 'Language'
  },
  {
    id: 'language--complex',
    name: 'Language (one of set)'
  },
  {
    id: 'days',
    name: 'Days'
  },
  {
    id: 'comparator',
    name: 'Comparison'
  },
  {
    id: 'comparator--time',
    name: 'Comparison (time)'
  },
  {
    id: 'operator',
    name: 'Operator'
  },
  {
    id: 'value--number',
    name: 'Magic value (number)'
  },
  {
    id: 'font',
    name: 'Font'
  },
  {
    id: 'align',
    name: 'Font align'
  },
  {
    id: 'key-value-pairs',
    name: 'Key-value pairs'
  },
  {
    id: 'sector-scope---user--cross-user--application',
    name: 'Scope (U/CU/A)'
  },
  // no flow steps use this right now
  // {
  //   id: 'sector-scope---user--cross-user',
  //   name: 'Scope (U/CU)'
  // }
  {
    id: 'event-scope',
    name: 'Event scope'
  },
  {
    id: 'action',
    name: 'Action'
  },
  {
    id: 'phone',
    name: 'Phone'
  },
  {
    id: 'email',
    name: 'Email'
  }
]

export default function EditConfiguration ({ defaultConfig, onChange, disabled }) {
  const rows = defaultConfig.map(dc => ({
    name: (
      <Input
        value={dc.name}
        onChange={v => {
          dc.name = v
          onChange(defaultConfig)
        }}
      />
    ),
    key: (
      <Input
        value={dc.key}
        onChange={v => {
          dc.key = v
          onChange(defaultConfig)
        }}
      />
    ),
    type: (
      <Dropdown
        selected={dc.type}
        items={TYPES}
        onChoose={v => {
          dc.type = v
          const foundDf = TYPES.find(_ => _.id === v)
          if (foundDf.defaultValue !== undefined) {
            dc.defaultValue = foundDf.defaultValue
          }
          onChange(defaultConfig)
        }}
      />
    ),
    defaultValue: <code>{(dc.defaultValue || '').toString()}</code>,
    actions: (
      <Button
        title='Delete'
        backgroundColor='#ff3838'
        InlineIcon={dashboardIcons.delete}
        onClick={() => {
          defaultConfig = defaultConfig.filter(_ => _.key !== dc.key)
          onChange(defaultConfig)
        }}
      />
    )
  }))

  function onControl (control) {
    control === 'add' && (() => {
      const newDc = [
        ...defaultConfig,
        {
          key: `newKey${defaultConfig.length + 1}`,
          type: 'boolean',
          name: 'Yes or no?',
          defaultValue: true
        }
      ]
      onChange && onChange(newDc)
    })()
  }

  return (
    <StyledComponent className={classnames('component--edit-configuration', { disabled })}>
      <H2>Configuration</H2>
      <ScrollableTable
        controls={!disabled ? ['add'] : undefined}
        onControl={onControl}
        columns={COLUMNS}
        rows={rows}
      />
    </StyledComponent>
  )
}

EditConfiguration.propTypes = {
  defaultConfig: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}
