import React, { useState, useRef } from 'react'
import styled from 'styled-components'

import PropTypes from 'prop-types'

import { Banner, Button } from '@openbox-app-shared'

import JSZip from 'jszip'
import { FFmpeg } from '@ffmpeg/ffmpeg'
import { fetchFile, toBlobURL } from '@ffmpeg/util'

const StyledUpload = styled.div`
  .buttons {
    height: 2.5rem;
    margin-top: 1rem !important;
    margin-bottom: 0 !important;
    > * {
      display: inline-block;
      vertical-align: top;
    }
    > * + * {
      margin-left: 0.5rem;
    }
  }
  .buttons + * {
    margin-top: 1rem;
  }
`

const LoadingOverlay = styled.div`
  margin: 0 !important;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background: rgba(0,0,0,0.4);
  z-index: 9999;
  color: #fff;
  font-size: 18px;
  flex-direction: column;
  text-align: center;
`

const Spinner = styled.div`
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`

const TIMEOUT_DURATION = 30 * 1000 // 30 seconds without logs considered a stall

export default function UploadZippedVideo({ value, label, show = false, onChange }) {
  const [zippedVideoUrl, setZippedVideoUrl] = useState(value)
  const [isProcessing, setIsProcessing] = useState(false)
  const [statusMessage, setStatusMessage] = useState(null)
  const [error, setError] = useState(null)
  const [loaded, setLoaded] = useState(false)

  const ffmpegRef = useRef(new FFmpeg())

  const loadFFmpeg = async () => {
    if (loaded) return
    setStatusMessage('Loading FFmpeg...')
    const ffmpeg = ffmpegRef.current
    ffmpeg.on('log', ({ message }) => {
      console.log('[FFmpeg Log]:', message)
      resetWatchdog()
    })

    await ffmpeg.load({
      coreURL: await toBlobURL('/ffmpeg-core.js', 'text/javascript'),
      wasmURL: await toBlobURL('/ffmpeg-core.wasm', 'application/wasm'),
    })
    console.log('[UploadZippedVideo]: FFmpeg loaded successfully.')
    setLoaded(true)
  }

  let watchdogTimer = null
  const resetWatchdog = () => {
    if (watchdogTimer) clearTimeout(watchdogTimer)
    watchdogTimer = setTimeout(() => {
      console.error('[UploadZippedVideo]: No progress for a while, throwing error.')
      setError('Processing is taking too long. Please try again with a different file.')
      setIsProcessing(false)
      setStatusMessage(null)
    }, TIMEOUT_DURATION)
  }

  const handleVideoProcessing = async (file) => {
    setIsProcessing(true)
    setError(null)
    setStatusMessage('Preparing...')

    try {
      console.log('[UploadZippedVideo]: Starting video processing...')
      await loadFFmpeg()
      const ffmpeg = ffmpegRef.current

      const videoUrl = URL.createObjectURL(file)
      console.log(`[UploadZippedVideo]: Generated video URL: ${videoUrl}`)

      setStatusMessage('Reading video file...')
      const videoData = await fetchFile(videoUrl)
      console.log('[UploadZippedVideo]: Video data fetched.')

      setStatusMessage('Writing video to virtual filesystem...')
      await ffmpeg.writeFile('input.mp4', videoData)
      console.log('[UploadZippedVideo]: input.mp4 written.')

      // Start the watchdog before ffmpeg.exec
      resetWatchdog()

      setStatusMessage('Extracting frames from video...')
      await ffmpeg.exec(['-i', 'input.mp4', '-vf', 'fps=15,scale=448:-1', '-q:v', '10', 'frame_%04d.jpg'])
      console.log('[UploadZippedVideo]: Frames extracted.')

      resetWatchdog() // reset after frames extraction
      setStatusMessage('Attempting to extract audio...')
      try {
        await ffmpeg.exec(['-i', 'input.mp4', '-q:a', '0', '-map', '0:a?', 'audio.m4a'])
        console.log('[UploadZippedVideo]: Audio extracted.')
      } catch (audioError) {
        console.log('[UploadZippedVideo]: No audio track found.')
      }

      resetWatchdog() // reset after audio extraction
      setStatusMessage('Reading extracted files...')
      const files = await ffmpeg.listDir('.')
      console.log('[UploadZippedVideo]: Directory contents:', files)

      const imageFiles = files
        .filter((f) => f.name.startsWith('frame_') && f.name.endsWith('.jpg'))
        .map(f => f.name)

      if (imageFiles.length === 0) {
        throw new Error('No frames extracted.')
      }

      const hasAudio = files.some((f) => f.name === 'audio.m4a')
      console.log(`[UploadZippedVideo]: Found ${imageFiles.length} frame(s). Audio present: ${hasAudio}`)

      resetWatchdog()
      setStatusMessage('Creating ZIP file...')
      const zip = new JSZip()
      const imagesFolder = zip.folder('images')

      for (const imgFile of imageFiles) {
        console.log(`[UploadZippedVideo]: Reading ${imgFile}...`)
        resetWatchdog()
        const imgData = await ffmpeg.readFile(imgFile)
        imagesFolder.file(imgFile, imgData)
      }

      if (hasAudio) {
        resetWatchdog()
        console.log('[UploadZippedVideo]: Reading audio.m4a...')
        const audioData = await ffmpeg.readFile('audio.m4a')
        const audioFolder = zip.folder('audio')
        audioFolder.file('audio.m4a', audioData)
      }

      const dataJson = {
        audio: hasAudio ? 'audio/audio.m4a' : null,
        video: imageFiles.map((f) => `images/${f}`),
      }
      console.log('[UploadZippedVideo]: Adding data.json to the ZIP...', dataJson)
      zip.file('data.json', JSON.stringify(dataJson, null, 2))

      resetWatchdog()
      setStatusMessage('Generating ZIP blob...')
      const zipBlob = await zip.generateAsync({ type: 'blob', compression: 'STORE' })
      console.log('[UploadZippedVideo]: ZIP blob generated.')

      resetWatchdog()
      setStatusMessage('Uploading ZIP file...')
      const uploadResponse = await window.sticky.upload(new File([zipBlob], 'video.zip', { type: 'application/zip' }))
      console.log('[UploadZippedVideo]: Upload completed. Response:', uploadResponse)

      if (uploadResponse && uploadResponse.url) {
        console.log(`[UploadZippedVideo]: ZIP uploaded successfully. URL: ${uploadResponse.url}`)
        setZippedVideoUrl(uploadResponse.url)
        onChange({ url: uploadResponse.url })
      } else {
        throw new Error('Invalid response from upload endpoint.')
      }

      setStatusMessage('Upload complete!')
      console.log('[UploadZippedVideo]: Processing and uploading completed successfully.')

      setTimeout(() => {
        setIsProcessing(false)
        setStatusMessage(null)
      }, 1000)

    } catch (err) {
      console.error('Error while processing the video:', err)
      setError('Error while processing the video. Please try again.')
      setIsProcessing(false)
      setStatusMessage(null)
    } finally {
      if (watchdogTimer) clearTimeout(watchdogTimer)
    }
  }

  return (
    <StyledUpload>
      <form>
        <input
          type="file"
          accept=".mp4"
          disabled={isProcessing}
          onChange={(e) => {
            const file = e.target.files[0]
            if (file) {
              console.log(`[UploadZippedVideo]: Selected file: ${file.name}, size: ${file.size} bytes`)
            }
            onChange({ url: file ? URL.createObjectURL(file) : null })
          }}
        />
      </form>
      <div className="buttons">
        <Button
          onClick={async () => {
            const file = document.querySelector('input[type=file]').files[0]
            if (!file) {
              alert('Please select a file first.')
              return
            }
            console.log('[UploadZippedVideo]: Starting process button clicked.')
            await handleVideoProcessing(file)
          }}
          disabled={isProcessing}
        >
          Process
        </Button>
      </div>
      {isProcessing && (
        <LoadingOverlay>
          <Spinner />
          <p>{statusMessage}</p>
        </LoadingOverlay>
      )}
      {!isProcessing && error && <Banner mood='bad'><p>{error}</p></Banner>}
      {!error && !isProcessing && show && zippedVideoUrl && <Banner><p>Your video has been processed.</p></Banner>}
    </StyledUpload>
  )
}

UploadZippedVideo.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string,
  show: PropTypes.bool,
  onChange: PropTypes.func
}
