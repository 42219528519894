import thing from './thing.inline.svg'
import application from './application.inline.svg'
import product from './product.inline.svg'
import teamMember from './teamMember.inline.svg'
import teamMembers from './teamMembers.inline.svg'
import media from './media.inline.svg'
import payment from './payment.inline.svg'
import check from './check.inline.svg'
import deploy from './deploy.inline.svg'
import note from './note.inline.svg'
import link from './link.inline.svg'
import location from './location.inline.svg'
import embed from './embed.inline.svg'
import developer from './developer.inline.svg'
import printer from './printer.inline.svg'
import getStarted from './getStarted.inline.svg'
import tags from './tags.inline.svg'
import filter from './filter.inline.svg'
import importVoid from './void.inline.svg'
import select from './select.inline.svg'
import selectAll from './selectAll.inline.svg'
import report from './report.inline.svg'
import buy from './buy.inline.svg'
import expand from './expand.inline.svg'
import goBack from './goBack.inline.svg'
import email from './email.inline.svg'
import phone from './phone.inline.svg'
import home from './home.inline.svg'
import copy from './copy.inline.svg'
import paste from './paste.inline.svg'
import dotDotDot from './dotDotDot.inline.svg'
import pos from './pos.inline.svg'
import livePayments from './livePayments.inline.svg'
import livePaymentsLinear from './livePaymentsLinear.inline.svg'
import questions from './questions.inline.svg'
import logOut from './logOut.inline.svg'
import executableCode from './executableCode.inline.svg'
import verify from './verify.inline.svg'
import events from './events.inline.svg'
import search from './search.inline.svg'
import stickypay from './stickypay.inline.svg'
import customTotal from './customTotal.inline.svg'
import arrowUp from './arrowUp.inline.svg'
import arrowDown from './arrowDown.inline.svg'
import burger from './burger.inline.svg'
import importDelete from './delete.inline.svg'
import storeData from './storeData.inline.svg'

export default {
  thing,
  application,
  product,
  teamMember,
  teamMembers,
  media,
  payment,
  check,
  deploy,
  note,
  link,
  location,
  embed,
  developer,
  printer,
  getStarted,
  tags,
  filter,
  void: importVoid,

  select,
  selectAll,
  report,
  buy,
  expand,
  goBack,
  email,
  phone,
  home,
  copy,
  paste,
  dotDotDot,
  pos,
  livePayments,
  livePaymentsLinear,
  questions,
  logOut,
  executableCode,
  verify,
  events,
  search,
  stickypay,
  customTotal,
  arrowUp,
  arrowDown,
  burger,
  delete: importDelete,
  storeData
}
