import React from 'react'
import PropTypes from 'prop-types'
import Box from './Box'

export default function DeveloperBox ({ user, application, whichBox, developerModeHeader, developerModeCode }) {
  const applicationGateway = window.sticky.Stickypay.getGateway(application.gateway)
  const canShow = user.can('develop') && applicationGateway && applicationGateway.canShowDeveloperBox && applicationGateway.canShowDeveloperBox(application, whichBox)
  return canShow && <Box isDarkMode developerModeHeader={developerModeHeader} developerModeCode={developerModeCode} />
}

DeveloperBox.propTypes = {
  user: PropTypes.object,
  application: PropTypes.object,
  whichBox: PropTypes.string,
  developerModeHeader: PropTypes.string,
  developerModeCode: PropTypes.string
}
