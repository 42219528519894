/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'

import { CustomHelmet } from '@openbox-app-shared'
import KeyValuePairs from '../../components/KeyValuePairs'
import dashboardIcons from '../../icons'

const StyledComponent = styled.div`
  padding-top: 1rem;
  .component--key-value-pairs {
    max-width: 32rem;
  }
`

function RenderChild ({ context, dbfProps }) {
  const { user } = context.props
  const { onChange } = dbfProps
  return (
    <StyledComponent>
      <CustomHelmet title={`Memory | Account | ${user.name}`} />
      <KeyValuePairs
        user={user}
        object={user.customData.get()}
        excludeKeys={['paymentId']}
        onUpdate={({ name, value }) => {
          if (value === null) {
            user.customData.delete(name)
          } else {
            user.customData.writeTo(name, value)
          }
          onChange('customData', user.customData)
        }}
      />
    </StyledComponent>
  )
}

const tab = {
  id: 'memory',
  name: (context) => 'Memory',
  inlineIcon: dashboardIcons.storeData,
  to: (context) => '/my/account/memory',
  child: props => <RenderChild {...props} />
}

export default tab
