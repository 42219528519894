import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Empty, List, ListItem } from '@openbox-app-shared'
import dashboardIcons from '../icons'
import { TagChooser } from './TagChooser'
import UserCircle from './UserCircle'

const StyledComponent = styled.ul`
`

const StyledRightSide = styled.div`
  > * {
    float: left;
  }
  > * + * {
    margin-left: 0.25rem;
  }
`

export default function FederatedUsersList ({ federatedUsers, onChoose, selected, linkOut = true, showTags = true }) {
  return (
    <>
      {federatedUsers.length === 0 && (
        <Empty>You don't have any team members yet.</Empty>
      )}
      {federatedUsers.length > 0 && <StyledComponent className='component--federated-users component--federated-users-list'>
        <List>
          {federatedUsers.map(federatedUser => {
            const currentFdAllTags = [
              federatedUser.can('lock--sticky-pay') && {
                id: 'lock--sticky-pay',
                name: 'Welcome menu',
                color: '#0abde3'
              }
            ]
              .filter(_ => _)
            const rightSideChildren = <StyledRightSide>
              {showTags && <TagChooser
                all={currentFdAllTags}
                set={window.sticky.newPatchableSet(currentFdAllTags.map(_ => _.id))}
                can={false}
              />}
              <UserCircle name={federatedUser.name} color={federatedUser.color} fixedWidthHeight={2} key={federatedUser.id} photoUrl={federatedUser.photoUrl} />
            </StyledRightSide>
            return (
              <ListItem
                id={federatedUser.id}
                key={federatedUser.id}
                InlineIcon={dashboardIcons.teamMember}
                selected={selected === federatedUser.id}
                goTo={linkOut ? `/me/team/${federatedUser.id}` : undefined}
                onChoose={!linkOut ? () => onChoose(federatedUser) : undefined}
                rightSideChildren={rightSideChildren}
              >
                {federatedUser.name}
              </ListItem>
            )
          })}
        </List>
      </StyledComponent>}
    </>
  )
}
FederatedUsersList.propTypes = {
  federatedUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  selected: PropTypes.string,
  onChoose: PropTypes.func,
  linkOut: PropTypes.bool,
  showTags: PropTypes.bool
}
