import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { icons, Link, CustomHelmet, Button, HotImage, Loading, Tag, Tags } from '@openbox-app-shared'

import Box from '../components/Box'
import ScrollableTable from '../components/ScrollableTable'
import { log } from '../log'
import { dispatch, subscribe } from '../redux'
import dashboardIcons from '../icons'

const COLUMNS = {
  logo: '',
  name: '',
  id: '',
  status: 'Status',
  permissions: 'Permissions',
  actions: ''
}

const StyledRoute = styled.div`
  padding: 1rem;
  /* logo */
    .component--table td:nth-child(1) {
      min-width: 4rem; max-width: 4rem;
    }
  /* name */
    .component--table td:nth-child(2) {
      min-width: 16rem;
    }
  /* id */
    .component--table td:nth-child(3) {
      width: 9.25rem; min-width: 9.25rem; max-width: 9.25rem;
      .buttons {
        > * {
          display: inline-block;
          vertical-align: top;
          height: auto !important;
          margin: 0;
          font-size: 80%;
          padding: 0;
        }
        > *:not(:first-child) {
          margin-left: 1rem;
        }
        > *.key {
          width: 3.25rem !important;
        }
      }
    }
  /* status */
    .component--table td:nth-child(4) {
      min-width: 8rem;
    }
  /* permissions */
    .component--table td:nth-child(5) {
      min-width: 12rem;
    }
  /* actions */
    .component--table td:nth-child(6) {
  ${props => {
    return css`
    min-width: calc(1.5rem + ${props.$actionsCount * 1.75}rem + ${(props.$actionsCount - 1) * 0.5}rem);
    max-width: calc(1.5rem + ${props.$actionsCount * 1.75}rem + ${(props.$actionsCount - 1) * 0.5}rem);
    `
  }};
    }
`

export default class Route extends Component {
  constructor () {
    super()
    this.state = {
      partners: undefined
    }
    this.PARTNER_UPDATE_GOOD = this.PARTNER_UPDATE_GOOD.bind(this)
    this.PARTNER_DELETE_GOOD = this.PARTNER_DELETE_GOOD.bind(this)
  }

  async componentDidMount () {
    log('[Route-partners] [componentDidMount]')
    this.getPartners()
    this.subscriptions = [
      subscribe('PARTNER_UPDATE_GOOD', this.PARTNER_UPDATE_GOOD),
      subscribe('PARTNER_DELETE_GOOD', this.PARTNER_DELETE_GOOD)
    ]
  }

  componentWillUnmount () {
    this.subscriptions && this.subscriptions.forEach(s => s())
  }

  async getPartners () {
    log('[Route-partners] [getPartners]')

    let partners
    try {
      partners = await window.sticky.internals.partners.getAll()
    } catch (e) {
      partners = []
    }
    log('[Route-partners] [getPartners]', { partners })
    this.setState({
      partners
    })
  }

  update (partner) {
    log('[Route-partners] [update]', { partner })
    dispatch('PARTNER_UPDATE', { partner })
  }
  PARTNER_UPDATE_GOOD () {
    log('[Route-partners] [PARTNER_UPDATE_GOOD]')
    this.getPartners()
  }

  delete (partner) {
    log('[Route-partners] [delete]', { partner })
    dispatch('PARTNER_DELETE', { partner })
  }
  PARTNER_DELETE_GOOD () {
    log('[Route-partners] [PARTNER_DELETE_GOOD]')
    this.getPartners()
  }

  async onControl (control) {
    control === 'add' && await (async () => {
      const r = await window.sticky.applications.blocks.renderInlineEventsButton(
        [
          {
            'id': '0e1f0565-5e05-471c-b855-bbe44c20527d',
            'config': {
              'labelForm': 'name',
              'label': 'Name',
              'type': 'Text',
              'value': '',
              'required': true
            }
          }
        ],
        'Add'
      )
      if (!r) {
        return
      }
      window.sticky.session.createEvent(
        {
          type: 'PARTNER_CREATE',
          ...r
        },
        undefined,
        undefined,
        undefined,
        (e) => {
          e && window.sticky.applications.blocks.showError(e.message)
          !e && window.location.reload()
        }
      )
    })()
  }

  render () {
    const { user } = this.props
    const { partners } = this.state
    const actionsCount = [
      user.can('delete-partner')
    ]
      .filter(e => e)
      .length + 1
    const rows = partners ? partners.map(partner => {
      return {
        logo: <HotImage src={partner.logoUrl} alt={partner.name} />,
        filterBy: [partner.id, partner.name, partner.code].join(' '),
        name: <Link to={`/partners/${partner.id}?partnerPrivateKey=${partner.privateKey}`} dontAttachQueryParameters target='_blank'>{partner.name}</Link>,
        id: <>
          <div className='buttons'>
            <Button className='key' isSecondary InlineIcon={dashboardIcons.copy} onClick={() => navigator.clipboard.writeText(partner.id)}>
              ID
            </Button>
            <Button className='key' isSecondary InlineIcon={dashboardIcons.copy} onClick={() => navigator.clipboard.writeText(partner.privateKey)}>
              PK
            </Button>
          </div>
        </>,
        status: <strong style={{ color: partner.statusColor }}>{partner.statusString}</strong>,
        permissions: <>
          <Tags>
            {partner.permissions.toTags().map(ae => <Tag tag={ae} key={ae} />)}
          </Tags>
        </>,
        actions: (<>
          <Button backgroundColor='#6C7A89' icon={icons.inverted.edit} onClick={() => this.update(partner)} />
          {user.can('delete-partner') ? <Button
            title='Delete'
            backgroundColor='#ff3838'
            InlineIcon={dashboardIcons.delete} onClick={() => this.delete(partner)}
          /> : null}
        </>)
      }
    }) : []

    log('[Route-partners] [render]', { user, partners, rows })

    return (
      <StyledRoute $actionsCount={actionsCount}>
        <CustomHelmet
          title='Partners'
        />
        {!partners && <Loading />}
        {partners && (
          <Box>
            <ScrollableTable
              controls={user.can('create-partner') ? ['add'] : []}
              onControl={this.onControl}
              filterableKey='filterBy'
              columns={COLUMNS}
              rows={rows}
            />
          </Box>
        )}
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  user: PropTypes.object
}
