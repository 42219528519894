import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { tags, isEmailValid, CustomHelmet, Form, Input, DynamicInput, PhoneNumberInput, LinkButton, ColourPicker, Loading, Banner, UploadImage } from '@openbox-app-shared'

import Box from '../../components/Box'
import EventExplorer from '../../components/Events/EventExplorer'
import AbstractAbstractPayments from '../../components/AbstractAbstractPayments'
import TabBar from '../../components/TabBar'
import CoolButton from '../../components/CoolButton'
import SaveButton from '../../components/SaveButton'

import { log } from '../../log'
import dashboardIcons from '../../icons'
import MainFrame from '../../components/MainFrame'
import { dispatch, subscribe } from '../../redux'
import CopyUrl from '../../components/CopyUrl'
import User from '../../components/User'
import H2 from '../../components/H2'
import { TagChooser } from '../../components/TagChooser'
import HeaderBar from '../../components/HeaderBar'

const StyledRoute = styled.div`
  padding: 1rem;
  .tab-container {
    .component--box + .component--box, .component--button + .component--box {
      margin-top: 1rem;
    }
    .component--box.thin {
      max-width: 24rem;
    }
    .component--box .component--h2 {
      margin-bottom: 0.5rem;
    }
    .component--box.features {
      padding-bottom: 0.25rem;
    }
    .component--box.actions {
      padding-bottom: 0;
    }
    .component--input.pin {
      width: 6.4rem;
    }
    .buttons {
      > * {
        display: inline-block;
        margin: 0 1rem 1rem 0;
        vertical-align: top;
      }
    }
  }
  aside {
    hr {
      border-color: #CFD8DC;
    }
  }
  .tab-container {
    margin-top: 1rem;
  }
`

const TABS = [
  {
    id: 'about',
    name: () => 'About',
    inlineIcon: dashboardIcons.teamMember,
    child: ({ context, formProps }) => {
      const { user } = context.props
      const { federatedUser, hasMadeUpdate } = context.state
      const theRoles = window.sticky.users.federated.ROLES.filter(_ => _.canShow({ user }))
      const isCurrentFu = (user.federatedUser && federatedUser && user.federatedUser.id === federatedUser.id) ? true : false
      return (
        <>
          <div className='buttons'>
            <SaveButton
              onSave={context.onSave}
              canSave={hasMadeUpdate && federatedUser.isValid}
              color='#4b7bec'
            />
            <LinkButton
              to='/me/team'
              isSecondary
              sameTab
            >
              ← Back to team members
            </LinkButton>
          </div>
          <Box className='thin'>
            <H2>Essentials</H2>
            <Form {...formProps}>
              <Input
                label='Name'
                name='name'
                value={federatedUser.name}
                isValid={federatedUser.name.length > 0}
              />
              <DynamicInput
                type='email'
                label='Log in email'
                name='email'
                value={federatedUser.email}
                isValid={isEmailValid(federatedUser.email)}
              />
            </Form>
          </Box>
          <Box className='fat actions'>
            <H2>Actions</H2>
            <div className='buttons'>
              <CoolButton
                InlineIcon={dashboardIcons.email}
                color='#4b7bec'
                onClick={() => {
                  dispatch('TRIGGER', { trigger: 'federated-user-invite-email', body: { federatedUserId: federatedUser.id } })
                }}
                isSecondary
              >
                Send welcome email
              </CoolButton>
              <CoolButton color='#4b7bec' onClick={() => dispatch('GET_INPUT', { why: 'federatedUser', type: 'password', string: '', entity: federatedUser, hint: <>Choose a new password:</> })} isSecondary InlineIcon={dashboardIcons.questions}>
                Change password
              </CoolButton>
              {user.federatedUserCan('master') && <CoolButton
                color='#4b7bec'
                onClick={() => {
                  dispatch('SURE_DELETE', { why: isCurrentFu ? 'federatedUserLogOut' : 'federatedUser', entity: federatedUser, hint: isCurrentFu ? 'Are you sure you want to delete yourself? You will not be able to log in again.' : undefined })
                }}
                InlineIcon={dashboardIcons.delete}
              >
                Delete
              </CoolButton>}
            </div>
          </Box>
          {theRoles.length > 0 && user.federatedUserCan('master') && <Box className='fat features'>
            <H2>Features</H2>
            <TagChooser
              all={theRoles}
              set={federatedUser.roles}
              onUpdate={id => {
                federatedUser.roles.toggle(id)
                context.onChange('roles', federatedUser.roles)
              }}
            />
          </Box>}
          {user.can('show-linear-operator-view') && <Box className='fat features'>
            <H2>Give to dos that are...</H2>
            <TagChooser
              all={tags.filter(t => t.doesAFederatedUserCare && t.canShow(user))}
              set={federatedUser.categories}
              onUpdate={id => {
                federatedUser.categories.toggle(id)
                context.onChange('categories', federatedUser.categories)
              }}
            />
          </Box>}
          {user.can('show-linear-operator-view') && (
            <Box className='thin'>
              <CopyUrl
                url={`${window.location.origin}/to-do-linear/${federatedUser.id}?userPrivateKey=${user.privateKey}&userPublicKey=${user.publicKey}`}
                buttonProps={{
                  backgroundColor: '#4b7bec'
                }}
              >
                Linear live payments
              </CopyUrl>    
            </Box>
          )}
          <Box className='thin'>
            <H2>{window.sticky._('STICKY_PAY')} / Take a payment</H2>
            <Form {...formProps}>
              <Input
                className='pin'
                label='PIN'
                name='pin'
                value={federatedUser.pin}
                isValid={federatedUser.pin.length > 0}
              />
              {federatedUser.pin === '0000' && (
                <Banner>
                  <p>
                    {federatedUser.name} won't enter a PIN.
                  </p>
                </Banner>
              )}
              <ColourPicker
                name='color'
                label='Color'
                currentColour={federatedUser.color}
              />
              <UploadImage
                label="Photo"
                url={federatedUser.photoUrl}
                onChange={({ url: photoUrl }) => {
                  formProps.onChange('photoUrl', photoUrl)
                }}
                svgsAllowed={false}
                color={'black'}
                canChangeToUndefined
              />
            </Form>
          </Box>
          <Box className='thin'>
            <H2>More</H2>
            <Form {...formProps}>
              <PhoneNumberInput
                name='phone'
                label='Phone number'
                value={federatedUser.phone}
                doValidate={false}
              />
              <Input label='Private key' value={federatedUser.privateKey} disabled />
            </Form>
          </Box>
        </>
      )
    }
  },
  {
    id: 'payments',
    name: context => (context.props.user.federatedUserCan('payments')) ? 'Payments' : undefined,
    inlineIcon: dashboardIcons.payment,
    child: ({ context, formProps }) => (
      <>
        <AbstractAbstractPayments user={context.props.user} query={`lastFederatedUserId=${context.state.federatedUser.id}`} />
      </>
    )
  },
  {
    id: 'activity',
    name: () => 'Activity',
    inlineIcon: dashboardIcons.events,
    child: ({ context, formProps }) => (
      <EventExplorer
        queryId='federated-user'
        queryObject={{ federatedUserId: context.state.federatedUser.id }}
      />
    )
  }
]

export default class Route extends Component {
  constructor(...args) {
    super(...args)
    this.state = {
      federatedUser: undefined,
      currentTab: (TABS.find(tab => tab.name(this)) || {}).id
    }
    this.SURE_DELETE_GOOD = this.SURE_DELETE_GOOD.bind(this)
    this.SAVED_FEDERATED_USER = this.SAVED_FEDERATED_USER.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onSave = this.onSave.bind(this)
  }

  async load() {
    const { federatedUserId } = this.props.match.params
    log('[Route-federatedUser] [load]', { federatedUserId })
    const federatedUser = await window.sticky.users.federated.get(federatedUserId)
    const federatedUsers = await window.sticky.users.federated.getAll()
    this.setState({
      federatedUser,
      federatedUsers
    })
  }

  async componentDidMount() {
    await this.load()
    this.subscriptions = [
      subscribe('SURE_DELETE_GOOD', this.SURE_DELETE_GOOD),
      subscribe('SAVED_FEDERATED_USER', this.SAVED_FEDERATED_USER)
    ]
  }

  componentWillUnmount() {
    this.subscriptions && this.subscriptions.forEach(s => s())
  }

  async onSave () {
    dispatch('SAVE_FEDERATED_USER', { entity: this.state.federatedUser })
  }

  SURE_DELETE_GOOD ({ why, entity }) {
    log('[Route-federatedUser] [SURE_DELETE_GOOD]', { why, entity })
    why === 'federatedUser' && (async () => {
      try {
        await window.sticky.users.federated.remove(entity)
        dispatch('REDIRECT', { to: '/me/team' })
      } catch ({ message }) {
        window.sticky.applications.blocks.showError(message, true)
      }
    })()
    why === 'federatedUserLogOut' && (async () => {
      try {
        await window.sticky.users.federated.remove(entity)
        window.sticky.internals.eraseFiniteToken()
        dispatch('REDIRECT', { to: '/log-in' })
      } catch ({ message }) {
        window.sticky.applications.blocks.showError(message, true)
      }
    })()
  }

  SAVED_FEDERATED_USER() {
    this.setState({
      hasMadeUpdate: false
    })
  }

  onChange(k, v) {
    const { federatedUser } = this.state
    federatedUser.patch({ [k]: v })
    this.setState({ hasMadeUpdate: true })
  }

  render () {
    const {
      user,
      match
    } = this.props
    const {
      federatedUser,
      federatedUsers,
      currentTab
    } = this.state

    log('[Route-federatedUser] [render]', { user, federatedUser, federatedUsers })

    const formProps = {
      onChange: this.onChange,
      onSubmit: this.onSave
    }

    const toShow = (() => {
      let _ = new Set(['log-out'])
      ;(user.federatedUserCan('applications') || user.federatedUserCan('things') || user.federatedUserCan('products') || user.federatedUserCan('payments')) && _.add('semantic-home')
      return Array.from(_)
    })()
    return (
      <StyledRoute>
        <CustomHelmet
          title={federatedUser && federatedUser.name}
        />
        {!federatedUser && <Loading />}
        {federatedUser && <>
          <MainFrame
            user={user}
            autoUi={this.props.autoUi}
            aside={<>
              <User user={user} whichPart={match.path} autoUi={this.props.autoUi} />
            </>}
            main={
              <>
                {(user.federatedUserCan('master') || user.federatedUserCan('payments')) && (
                  <>
                    <HeaderBar text={`Team › ${federatedUser.name}`} Icon={dashboardIcons.teamMembers} user={user} />
                    <Box>
                      <TabBar
                        tabs={TABS
                          .filter(t => t.name(this))
                          .map(t => ({ ...t, name: t.name(this), child: t.child({ context: this, formProps }) }))}
                        selectedTab={currentTab}
                        onClick={currentTab => this.setState({ currentTab })}
                      />
                    </Box>
                  </>
                )}
              </>
            }
          />
        </>}
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  federatedUserId: PropTypes.string,
  autoUi: PropTypes.arrayOf(PropTypes.string)
}
