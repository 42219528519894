import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

const StyledLoading = styled.div`
  @keyframes gmc-loading {
    100% { transform: rotate(360deg); }
  }
  display: block;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: #6C7A89;
  opacity: 0.25;
  svg {
    display: block;
    width: 2.5rem;
    margin: 0 auto 0 auto;
    animation: gmc-loading 4s linear infinite;
    color: inherit;
    // background-color: blue;
  }
  p {
    margin: 0 auto 0 auto;
    text-align: center;
    color: inherit;
  }
  svg + p {
    margin-top: 1rem;
  }
`

const Loading = ({ style, loadingWhat }) => {
  return (
    <StyledLoading style={style} className='component--loading'>
      <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 2v4"/><path d="m12 18v4"/><path d="m4.93 4.93 2.83 2.83"/><path d="m16.24 16.24 2.83 2.83"/><path d="m2 12h4"/><path d="m18 12h4"/><path d="m4.93 19.07 2.83-2.83"/><path d="m16.24 7.76 2.83-2.83"/></svg>
      {loadingWhat && <p className='well-known--loading-what'>{loadingWhat}</p>}
    </StyledLoading>
  )
}
Loading.propTypes = {
  style: PropTypes.object,
  loadingWhat: PropTypes.string
}

export default Loading
