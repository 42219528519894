import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Link, List, ListItem, Banner, LinkButton } from '@openbox-app-shared'
import _ from '../_'
import dashboardIcons from '../icons'
import getLogOutUrl from '../getLogOutUrl'
import FederatedUser from './FederatedUser'
import PartnerCell from '../../../../openbox-app-shared/components/PartnerCell'

const StyledComponent = styled.div`
  .back-to-yeet-yeet {
    display: block;
    width: 100% !important;
    margin-bottom: 1rem;
    button {
      width: 100%;
    }
  }
  max-width: 350px;
  .component--list-item {
    background-color: transparent;
    border: 0.125rem solid transparent;
    > .icon {
      top: 0.375rem !important;
    }
    > a p span, > span p span {
      margin-top: calc(0.5rem - 0.125rem);
    }
  }
  .component--list-item[id='applications'] {
    background-color: #26de81 !important;
    border-color: #26de81;
  }
  .component--list-item[id='things'] {
    background-color: #f7b731 !important;
    border-color: #f7b731;
  }
  .component--list-item[id='products'] {
    background-color: #a55eea !important;
    border-color: #a55eea;
  }
  .component--list-item[id='federatedUsers'] {
    background-color: #4b7bec !important;
    border-color: #4b7bec;
  }
  .component--list-item.selected {
    border-color: white;
  }
  .component--banner {
    .component--button.is-secondary {
      color: #1A1F35;
      border-color: #1A1F35;
      background-color: transparent;
    }
  }
  .component--banner + * {
    margin-top: 1rem;
  }
  .list-wrapper {
    padding: 2px;
    border-radius: 6px;
    box-shadow: 0 3px 6px 0 rgb(60 66 87 / 20%), 0 1px 2px 0 rgb(0 0 0 / 20%);
    > .component--h2 {
      font-size: 80%;
      line-height: 1rem;
      color: white;
      text-transform: uppercase;
      margin: 1rem 0 0 1rem;
      opacity: 0.5;
      strong {
        letter-spacing: 2px;
      }
    }
    .component--federated-user {
      margin: 0.5rem;
      .more strong {
        color: white;
      }
    }
    &.square .component--list {
      display: grid;
      grid-gap: 0.5rem;
      grid-template-columns: 1fr 1fr;
      .component--list-item {
        margin-top: 0;
      }
    }
  }
  .component--list {
    padding: calc(0.5rem);
    border-radius: 5px;
    color: white;
    .component--list-item {
      box-shadow: none;
      height: 2.5rem;
      .icon {
        top: 0.5rem;
        left: 0.5rem;
        > * {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
      > a, > span {
        height: 2.25rem;
        font-size: 105%;
      }
      &:not(.selected):hover {
        border-color: #ffffff80;
        background-color: transparent;
      }
    }
  }
  .list-wrapper + .list-wrapper {
    margin-top: 1rem;
  }
  .component--partner-cell {
    margin-top: 1rem;
  }
  .federated-user-container {
    text-decoration: none;
  }
  `

function CoolListItem({ children, customKey, ...props }) {
  return <ListItem {...props} id={customKey}><strong>{children}</strong></ListItem>
}
CoolListItem.propTypes = {
  customKey: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default function User({ user, whichPart, toShow, autoUi }) {
  const { loaded: supportStatus } = window.HubSpotConversations ? window.HubSpotConversations.widget.status() : { loaded: false }
  const [supportOn, setSupportOn] = useState(supportStatus)

  const toReallyShow = Array.isArray(toShow) ? toShow : [
    'semantic-home',
    '/my/account',
    '/analytics/home',
    '/analytics/consumers',
    '/analytics/payments',
    '/analytics/activity',
    '/analytics/check-in',

    '/sticky-pay',

    '/developer',
    '/store',
    '/activate-stickies',

    'support',
    'log-out',
    '/home',

    'logo'
  ]
  const listItems1 = [
    !user.federatedUserCan('lock--sticky-pay', false) && toReallyShow.includes('semantic-home') && user.federatedUserCan('applications') && <CoolListItem
      customKey='applications' key='applications'
      InlineIcon={dashboardIcons.application}
      goTo='/me/flows'
      selected={window.location.pathname === '/me/flows' || whichPart === '/me/flows/:applicationId' || whichPart === '/me/flows/:applicationId/:view' || whichPart === '/me/flows/:applicationId/:view/:viewSubroot'}
    >
      {window.sticky._('APPLICATIONS')}
    </CoolListItem>,
    !user.federatedUserCan('lock--sticky-pay', false) && toReallyShow.includes('semantic-home') && user.federatedUserCan('things') && <CoolListItem
      customKey='things' key='things'
      InlineIcon={dashboardIcons.thing}
      goTo='/me/stickies'
      selected={window.location.pathname === '/me/stickies' || whichPart === '/me/stickies/:thingId'  || whichPart === '/me/stickies/:thingId/:view'}
    >
      Stickies
    </CoolListItem>,
    !user.federatedUserCan('lock--sticky-pay', false) && toReallyShow.includes('semantic-home') && user.federatedUserCan('products') && <CoolListItem
      customKey='products' key='products'
      InlineIcon={dashboardIcons.product}
      goTo='/me/products'
      selected={window.location.pathname === '/me/products' || whichPart === '/me/products/:productId' || whichPart === '/me/products/:productId/:view'}
    >
      Products
    </CoolListItem>,
    !user.federatedUserCan('lock--sticky-pay', false) && toReallyShow.includes('semantic-home') && user.federatedUserCan('master') && <CoolListItem
      customKey='federatedUsers' key='federatedUsers'
      InlineIcon={dashboardIcons.teamMembers}
      goTo='/me/team'
      selected={window.location.pathname === '/me/team' || whichPart === '/me/team/:federatedUserId'}
    >
      Team
    </CoolListItem>
  ]
    .filter(e => e)

  const listItems2 = [
    !user.federatedUserCan('lock--sticky-pay', false) && toReallyShow.includes('/analytics/payments') && user.federatedUserCan('payments') && <CoolListItem
      customKey='/analytics/payments' key='/analytics/payments'
      InlineIcon={dashboardIcons.payment}
      goTo='/analytics/payments'
      selected={window.location.pathname === '/analytics/payments'}
    >
      Payments
    </CoolListItem>,
    !user.federatedUserCan('lock--sticky-pay', false) && toReallyShow.includes('/analytics/home') && user.federatedUserCan('master') && <CoolListItem
      customKey='/analytics/home' key='/analytics/home'
      InlineIcon={dashboardIcons.report}
      goTo={whichPart === '/me/payments/:paymentId' ? '/analytics/payments' : '/analytics/home'}
      selected={window.location.pathname === '/analytics/home'}
    >
      Analytics
    </CoolListItem>,
    !user.federatedUserCan('lock--sticky-pay', false) && toReallyShow.includes('/analytics/activity') && user.federatedUserCan('master') && <CoolListItem customKey='/analytics/activity' key='/analytics/activity' InlineIcon={dashboardIcons.events} goTo='/analytics/activity' selected={window.location.pathname === '/analytics/activity'}>Activity</CoolListItem>,
    !user.federatedUserCan('lock--sticky-pay', false) && toReallyShow.includes('/analytics/consumers') && user.federatedUserCan('master') && <CoolListItem customKey='/analytics/consumers' key='/analytics/consumers' InlineIcon={dashboardIcons.teamMember} goTo='/analytics/consumers' selected={window.location.pathname === '/analytics/consumers'}>People</CoolListItem>,
    !user.federatedUserCan('lock--sticky-pay', false) && toReallyShow.includes('/analytics/check-in') && user.federatedUserCan('master') && <CoolListItem customKey='/analytics/check-in' key='/analytics/check-in' InlineIcon={dashboardIcons.location} goTo='/analytics/check-in' selected={window.location.pathname === '/analytics/check-in'}>Check ins</CoolListItem>
  ]
    .filter(e => e)

  const stickyPayUrl = (() => {
    if (user.federatedUsersWithOpsManagerRolePrivateKey) {
      return `/sticky-pay-sticky-pay?federatedUserPrivateKey=${user.federatedUsersWithOpsManagerRolePrivateKey}&autoUi=browserBack`
    }
    if (user.federatedUserCan('ops-manager') && user.federatedUser) {
      return '/sticky-pay-sticky-pay?autoUi=browserBack'
    }
  })()
  const listItems3 = [
    user.federatedUserCan('lock--sticky-pay', false) && <CoolListItem customKey='/sticky-pay' key='/sticky-pay' InlineIcon={dashboardIcons.goBack} goTo='/sticky-pay'>Exit</CoolListItem>,
    !user.federatedUserCan('lock--sticky-pay', false) && toReallyShow.includes('/sticky-pay') && stickyPayUrl && <CoolListItem customKey='/sticky-pay-sticky-pay' key='/sticky-pay-sticky-pay' InlineIcon={dashboardIcons.pos} goTo={stickyPayUrl}>{window.sticky._('STICKY_PAY')}</CoolListItem>,
    !user.federatedUserCan('lock--sticky-pay', false) && toReallyShow.includes('/sticky-pay') && user.federatedUserCan('live-payments') && <CoolListItem customKey='/to-do' key='/to-do' InlineIcon={dashboardIcons.livePayments} goTo='/to-do'>Live payments</CoolListItem>,
    !user.federatedUserCan('lock--sticky-pay', false) && toReallyShow.includes('/sticky-pay') && user.can('show-linear-operator-view') && <CoolListItem customKey='/to-do-linear' key='/to-do-linear' InlineIcon={dashboardIcons.livePaymentsLinear} goTo='/to-do-linear'>Linear live payments</CoolListItem>
  ]
    .filter(e => e)

  const listItems4 = [
    toReallyShow.includes('/my/account') && user.federatedUserCan('master') && <CoolListItem customKey='/my/account' key='/my/account' InlineIcon={dashboardIcons.teamMember} goTo='/my/account/about' selected={['/my/account/:entity', '/my/account/:entity/:subEntity'].includes(whichPart)}>Account</CoolListItem>,
    toReallyShow.includes('support') && user.can('get-support') && <CoolListItem
      customKey='support'
      key='support'
      InlineIcon={dashboardIcons.questions}
      selected={supportOn}
      onChoose={() => {
        if (!window.HubSpotConversations) {
          return
        }
        if (supportOn) {
          window.HubSpotConversations.widget.remove()
        } else {
          window.HubSpotConversations.widget.load()
          window.HubSpotConversations.widget.open()
        }
        setSupportOn(!supportOn)
      }}
    >
      Help
    </CoolListItem>,
    !user.federatedUserCan('lock--sticky-pay', false) && toReallyShow.includes('/developer') && user.can('develop') && user.federatedUserCan('master') && <CoolListItem
      customKey='/developer' key='/developer'
      InlineIcon={dashboardIcons.developer}
      goTo='/developer/about'
      selected={
        [
          '/developer/:view',
          '/developer/:view/:entityId',
          '/developer/:view/:entityId/:viewSubroot'
        ]
          .includes(whichPart)
      }
    >
      Developer
    </CoolListItem>,
    toReallyShow.includes('log-out') && <CoolListItem
      customKey='log-out' key='log-out'
      InlineIcon={dashboardIcons.logOut}
      onChoose={() => {
        window.sticky.users.logOut()
        const logOutRedirectTo = getLogOutUrl()
        window.location = `/log-in${logOutRedirectTo ? `?logInRedirectTo=${encodeURIComponent(logOutRedirectTo)}` : ''}`
      }}
    >
      Log out
    </CoolListItem>
  ]
    .filter(e => e)

  return (
    <StyledComponent className='component--user'>
      {autoUi.includes('backToYeetYeet') && <LinkButton
        className='back-to-yeet-yeet partner-me'
        to='/sticky-pay'
        sameTab
        backgroundColor='transparent'
      >
        ← Exit
      </LinkButton>}
      {user.isLoggedInAsPartner && (
        <Banner mood='very-bad'>
          <p>
            Don't share the URL of this page.
          </p>
        </Banner>
      )}
      {user.isLoggedInAsPartner && user.lightMode && (
        <Banner>
          <p>
            "Use forced long polling for Real Time Flow Messages (RTFM)" is on.
          </p>
          <p>
            This makes RTFM work on an Apple Watch with a performance penalty.
          </p>
        </Banner>
      )}
      {user.federatedUser && <div className='list-wrapper distinct'>
        <Link to={`/me/team/${user.federatedUser.id}`} className='federated-user-container'>
          <FederatedUser federatedUser={user.federatedUser} />
        </Link>
      </div>}
      {listItems3.length > 0 && <div className='list-wrapper'><List>{listItems3}</List></div>}
      {listItems1.length > 0 && <div className='list-wrapper'><List>{listItems1}</List></div>}
      {listItems2.length > 0 && <div className='list-wrapper'><List>{listItems2}</List></div>}
      {listItems4.length > 0 && <div className='list-wrapper'><List>{listItems4}</List></div>}
      {user.partner && toReallyShow.includes('logo') && <PartnerCell className='aside-hide-before-breakpoint' />}
    </StyledComponent>
  )
}
User.propTypes = {
  user: PropTypes.object,
  whichPart: PropTypes.string,
  toShow: PropTypes.arrayOf(PropTypes.string),
  autoUi: PropTypes.arrayOf(PropTypes.string)
}
