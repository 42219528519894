import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { CustomHelmet, Loading } from '@openbox-app-shared'
import PaymentInvoice from '../../components/PaymentInvoice'

import { log } from '../../log'
import _ from '../../_'

const StyledRoute = styled.div`
  padding: 1rem;
`

export default class Route extends Component {
  constructor () {
    super()
    this.state = {
      payment: undefined
    }
  }

  async componentDidMount () {
    const {
      paymentId
    } = this.props.match.params
    const { partnerPrivateKey } = window.sticky.getPayload()
    log('[Route-Stickypay-paymentUserPrivateKey] [componentDidMount] 1', { paymentId, partnerPrivateKey })

    window.sticky.pay.setQuery(`userPrivateKey=${this.props.user.privateKey}${typeof partnerPrivateKey === 'string' ? `&partnerPrivateKey=${partnerPrivateKey}` : ''}`)
    const thingPayments = [...(await window.sticky.pay.getAll())].reverse()
    window.sticky.pay.resetQuery()
    const payment = thingPayments.find(p => p.id === paymentId)

    log('[Route-Stickypay-paymentUserPrivateKey] [componentDidMount] 2', { paymentId, payment, partnerPrivateKey })
    this.setState({
      payment
    })
  }

  render () {
    const {
      user
    } = this.props
    const {
      payment
    } = this.state
    log('[Route-Stickypay-paymentUserPrivateKey] [render]', { user, payment })

    const toName = user.isLoggedInAsPartner ? user.partner.bankDetails.readFrom('invoiceName') : user.bankDetails.readFrom('invoiceName')
    return (
      <StyledRoute>
        <CustomHelmet
          title='Invoice'
        />
        {!payment && <Loading />}
        {payment && <PaymentInvoice
          user={user}
          payment={payment}
        />}
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  paymentId: PropTypes.string
}
