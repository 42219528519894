/* eslint-disable react/prop-types */

import React from 'react'
import styled from 'styled-components'
import { icons, Button, Loading, CustomHelmet } from '@openbox-app-shared'
import _ from '../../../_'
import dashboardIcons from '../../../icons'
import FederatedUsersList from '../../../components/FederatedUsersList'

const StyledComponent = styled.div`
  .component--button {
    margin-bottom: 1rem;
  }
  .component--list {
    max-width: 32rem;
  }
`

function RenderChild ({ context }) {
  const { user } = context.props
  return (
    <StyledComponent className='user--federated-users'>
      <CustomHelmet title={`Team | ${user.name}`} />
      {!(Array.isArray(context.state.federatedUsers) && user) && <Loading />}
      {(Array.isArray(context.state.federatedUsers) && user) && (
        <>
          <Button
            onClick={context.createFederatedUser}
            backgroundColor='#4b7bec'
            className={context.state.federatedUsers.length === 0 ? 'openbox--pulsing-1' : undefined}
            icon={icons.inverted.add}
            id='FEDERATED_USERS_ADD'
          >
            {_('FEDERATED_USERS_ADD')}
          </Button>
          <FederatedUsersList federatedUsers={context.state.federatedUsers} />
        </>
      )}
    </StyledComponent>
  )
}

const tab = {
  id: 'team',
  name: (context) => _('FEDERATED_USERS'),
  inlineIcon: dashboardIcons.teamMembers,
  to: (context) => '/me/team',
  child: function Child (props) {
    return <RenderChild {...props} />
  }
}

export default tab
