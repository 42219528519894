import React from 'react'
import styled from 'styled-components'
import { isEmailValid, Loading, Form, Input, Label, Switch, Button } from '@openbox-app-shared'
import Box from '../../../components/Box'
import FederatedUsersGrid from '../../../components/FederatedUsersGrid'
import dashboardIcons from '../../../icons'

const StyledComponent = styled.div`
  padding-top: 1rem;
  .component--box.generic {
    max-width: 24rem;
  }
  .component--box + .component--box,
  .component--form + label,
  .component--form + .component--button,
  .component--federated-users-grid + .component--form {
    margin-top: 1rem;
  }
  img.qr {
    margin-bottom: 1rem;
    width: 8rem;
    height: 8rem;
    border: 2px solid black;
    border-radius: 6px;
  }
  label + .component--federated-users-grid {
    margin-top: 0.5rem;
  }
  .component--federated-users-grid {
    width: fit-content;
    > * {
      width: 3rem;
      height: 3rem;
    }
  }
`

const tab = {
  id: 'advanced',
  name: () => '',
  inlineIcon: dashboardIcons.dotDotDot,
  to: (context) => context.state.thing ? `/me/stickies/${context.state.thing.id}/advanced` : undefined,
  child: function Child (context, extraProps) {
    const { user } = context.props
    const { thing, federatedUsers } = context.state
    const asImage2 = thing && !thing.isVirtual && window.sticky.things.getImage(thing.id)
    return <StyledComponent className='tab'>
      {!thing && <Loading />}
      {thing && (
        <>
          <Box className='generic'>
            {asImage2 && <img className='qr' src={asImage2} alt='Sticker image' />}
            <Form {...extraProps.debouncedProps}>
              <Switch
                checked={thing.isVirtual}
                name='isVirtual'
              >
                Virtual
              </Switch>
              <Input
                label='Your ID'
                name='theirId'
                value={thing.theirId}
              />
            </Form>
            <Label>Team members</Label>
            <FederatedUsersGrid
              federatedUsers={federatedUsers}
              chosenFederatedUserIds={thing.federatedUserIds}
              onChoose={fu => {
                thing.federatedUserIds = thing.federatedUserIds.filter(fuId => {
                  return federatedUsers.find(fu => fu.id === fuId)
                })
                if (thing.federatedUserIds.includes(fu.id)) {
                  thing.federatedUserIds = thing.federatedUserIds.filter(fuId => {
                    return fuId !== fu.id
                  })
                } else {
                  thing.federatedUserIds.push(fu.id)
                }
                extraProps.debouncedProps.onChange('federatedUserIds', thing.federatedUserIds)
              }}
            />
            <Form>
              {/* <Switch
                checked={thing.isKidSafe}
                name='isKidSafe'
              >
                Kid safe
              </Switch> */}
              <Input
                label='ID'
                name='id'
                disabled
                value={thing.id}
              />
            </Form>
          </Box>
          <Box isDarkMode developerModeHeader='Take a payment' developerModeCode={`curl '${window.sticky.internals.urls.API}/v2/trigger/public--payByThing' \\
-H 'authorization: Bearer ${user.publicKey}//' \\
-H 'content-type: application/json' \\
--data-raw '{ "total": 100, "currency": "GBP", "thingId": "${thing.id}" }'`} />
        </>
      )}
    </StyledComponent>
  }
}

export default tab
