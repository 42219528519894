/* eslint-disable quotes */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react/prop-types */

import React, { useState } from 'react'
import styled from 'styled-components'
import { icons, sessionKeysAndHowToValidateThem, sessionKeysAndHowToValidateThemByKey, Input, NumberInput, DayPicker, Label, TimePicker, Button, List, Dropdown, ListItem, Banner, PatchableSetList, Switch, Form } from '@openbox-app-shared'
import Box from '../../../components/Box'
import H2 from '../../../components/H2'
import dashboardIcons from '../../../icons'

const FLOW_TYPES = [
  {
    id: 'buy--no-repeat',
    name: 'Products (don\'t show "Order again" button)'
  },
  {
    id: 'buy',
    name: 'Products (show "Order again" button)'
  },
  {
    id: 'buy-now',
    name: 'Products ("Buy for...")'
  },
  {
    id: 'book-intermediate',
    name: 'Bookings (future completion step)'
  }
]

const StyledPa = styled.div`
  > * + *, .component--box + .component--box {
    margin-top: 1rem;
  }
  .component--box .component--switch + .component--dropdown,
  .component--box .component--dropdown + .component--switch,
  .component--box .component--switch + .component--switch {
    margin-top: 1rem;
  }
  .component--dropdown, .component--list {
    max-width: 360px;
  }
  .component--input.type--code {
    height: 12rem;
  }
  .grid {
    > * + * {
      margin-top: 1rem;
    }
  }
  @media only screen and (min-width: 960px) {
    .grid {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr 1fr;
      > * {
        margin-top: 0 !important;
      }
    }
  }
`

const PartProduct = ({ context }) => {
  const { state: { application }, onUpdate } = context
  return (
    <>
      <Switch
        checked={application.stickyretail.readFrom('oneTapCheckout') || false}
        onChange={v => {
          application.stickyretail.writeTo('oneTapCheckout', v)
          onUpdate({ 'stickyretail': application.stickyretail })
        }}
      >
        One tap to pay
      </Switch>
      <Switch
        checked={application.stickyretail.readFrom('configureQuantity') || false}
        onChange={v => {
          application.stickyretail.writeTo('configureQuantity', v)
          onUpdate({ 'stickyretail': application.stickyretail })
        }}
      >
        + / - quantity
      </Switch>
      <Switch
        checked={typeof application.stickyretail.readFrom('confirmCart') === 'boolean' ? application.stickyretail.readFrom('confirmCart') : false}
        onChange={v => {
          application.stickyretail.writeTo('confirmCart', v)
          onUpdate({ 'stickyretail': application.stickyretail })
        }}
      >
        Confirm bag
      </Switch>
      <Switch
        checked={typeof application.stickyretail.readFrom('showPrices') === 'boolean' ? application.stickyretail.readFrom('showPrices') : true}
        onChange={v => {
          application.stickyretail.writeTo('showPrices', v)
          onUpdate({ 'stickyretail': application.stickyretail })
        }}
      >
        Show prices
      </Switch>
      <Switch
        checked={typeof application.stickyretail.readFrom('sellProducts') === 'boolean' ? application.stickyretail.readFrom('sellProducts') : true}
        onChange={v => {
          application.stickyretail.writeTo('sellProducts', v)
          onUpdate({ 'stickyretail': application.stickyretail })
        }}
      >
        Allow products to be added to the bag
      </Switch>
      <hr />
      <Switch
        checked={typeof application.stickyretail.readFrom('addProductByThingName') === 'boolean' ? application.stickyretail.readFrom('addProductByThingName') : false}
        onChange={v => {
          application.stickyretail.writeTo('addProductByThingName', v)
          onUpdate({ 'stickyretail': application.stickyretail })
        }}
      >
        Add a product before payment with the same name as the sticky
      </Switch>
      <Switch
        checked={typeof application.stickyretail.readFrom('showQrClientSide') === 'boolean' ? application.stickyretail.readFrom('showQrClientSide') : false}
        onChange={v => {
          application.stickyretail.writeTo('showQrClientSide', v)
          onUpdate({ 'stickyretail': application.stickyretail })
        }}
      >
        Show QR code
      </Switch>
    </>
  )
}

const PartFlowType = ({ context }) => {
  const { state: { application }, onUpdate } = context
  return (
    <Box>
      <H2>Language adjustment</H2>
      <List>
        {FLOW_TYPES.map(a => {
          return (
            <ListItem
              selected={(application.stickyretail.readFrom('flowType') || FLOW_TYPES[0].id) === a.id}
              onChoose={(id) => {
                application.stickyretail.writeTo('flowType', id)
                onUpdate({ 'stickyretail': application.stickyretail })
              }}
              key={a.id}
              id={a.id}
              icon={icons.generic.listItem}
            >
              {a.name}
            </ListItem>
          )
        })}
      </List>
    </Box>
  )
}

const PartProductCategories = ({ context }) => {
  const [filter, setFilter] = useState('')
  const { state: { application, productCategories }, onUpdate } = context
  const forPs = productCategories.filter(p => p.doesMatchFilter(filter))
    .map(p => {
      return {
        id: p.id,
        name: p.name
      }
    })
  return (
    <Box>
      <H2>Product categories shown</H2>
      {application.productCategories.size === 0 && (
        <Banner mood='good'>
          <p>
            You haven't chosen any product categories. Every product category will be shown.
          </p>
        </Banner>
      )}
      <PatchableSetList
        all={forPs}
        set={application.productCategories}
        onUpdate={() => {
          onUpdate({
            productCategories: application.productCategories
          })
        }}
        filter={filter}
        setFilter={setFilter}
      />
    </Box>
  )
}

const PartQuestions = ({ context, extraProps }) => {
  const { advanced } = window.sticky.getPayload()
  const { application } = context.state
  const skCollectionTime = sessionKeysAndHowToValidateThemByKey.collectionTime
  let [skCollectionTimeConfig, setSkCollectionTimeConfig] = useState(
    application.stickyretail.readFrom('skCollectionTimeConfig')
      ?
      JSON.parse(application.stickyretail.readFrom('skCollectionTimeConfig'))
      :
      {
        ...skCollectionTime.defaultValue
      }
  )

  let collectionTimeTimes = skCollectionTimeConfig.times.length === 0 ? [] :skCollectionTimeConfig.times
    .split(',')
    .map(t => {
      const [startTime, endTime] = t.split('--TO--').map(p => parseInt(p, 10))
      return {
        startTime,
        endTime
      }
    })

  const reallyOnChangeStickyretail = (k, v) => {
    application.stickyretail.writeTo(k, v)
    extraProps.debouncedProps.onChange('stickyretail', application.stickyretail)
  }

  const reallyOnChangeCollectionTime = (k, v) => {
    skCollectionTimeConfig = {
      ...skCollectionTimeConfig,
      [k]: v
    }
    setSkCollectionTimeConfig(skCollectionTimeConfig)
    application.stickyretail.writeTo('skCollectionTimeConfig', JSON.stringify(skCollectionTimeConfig))
    extraProps.debouncedProps.onChange('stickyretail', application.stickyretail)
  }
  const reallyOnChangeTime = () => {
    const string = collectionTimeTimes.map(({ startTime, endTime }) => `${startTime}--TO--${endTime}`).join(',')
    reallyOnChangeCollectionTime('times', string)
  }

  return (
    <Box className='new-questions'>
      <H2>Payment questions</H2>
      <Form onChange={reallyOnChangeStickyretail}>
        <Switch
          name='alwaysReconfirmCus'
          checked={application.stickyretail.readFrom('alwaysReconfirmCus') || false}
        >
          Always confirm
        </Switch>
      </Form>
      <hr />
      <Form {...extraProps.debouncedProps}>
        {sessionKeysAndHowToValidateThem
          .filter(k => advanced ? true : k.isUserConfigurable)
          .map(ask => {
            return (
              <Switch
                key={`ask-${ask.name}`}
                checked={application.sessionKeys.has(ask.name)}
                name={ask.name}
                setName='sessionKeys'
                set={application.sessionKeys}
              >
                {ask.text}
              </Switch>
            )
          })}
      </Form>
      {application.sessionKeys.has('collectionTime') && (
        <div className='config--collection-time'>
          <Box>
            <H2>Collection time</H2>
            <Form onChange={reallyOnChangeCollectionTime}>
              <Input
                name='label'
                value={skCollectionTimeConfig.label}
                label='Label'
              />
              <NumberInput
                name='minimumWait'
                value={skCollectionTimeConfig.minimumWait}
                label='Minimum time before a slot (mins)'
              />
              <NumberInput
                name='frequency'
                value={skCollectionTimeConfig.frequency}
                label='Time between slots (min)'
              />
              <DayPicker
                name='days'
                days={skCollectionTimeConfig.days}
                label='Days'
                turnedOnColor='#26de81'
              />
              <Label>Times</Label>
              <div className='times'>
                {collectionTimeTimes.map(({ startTime, endTime }, ip) => {
                  return (
                    <div key={`time--${startTime}--${endTime}--${ip}`} className='time'>
                      <TimePicker
                        label='From'
                        time={startTime}
                        timezone={0}
                        onChange={v => {
                          collectionTimeTimes[ip].startTime = v
                          reallyOnChangeTime()
                        }}
                      />
                      <TimePicker
                        label='To'
                        time={endTime}
                        timezone={0}
                        onChange={v => {
                          collectionTimeTimes[ip].endTime = v
                          reallyOnChangeTime()
                        }}
                      />
                      <Button
                        title='Delete'
                        backgroundColor='#ff3838'
                        InlineIcon={dashboardIcons.delete}
                        onClick={() => {
                          collectionTimeTimes = collectionTimeTimes.filter((_, i) => {
                            return (i !== ip)
                          })
                          reallyOnChangeTime()
                        }}
                      />
                    </div>
                  )
                })}
                <Button
                  icon={icons.inverted.add}
                  backgroundColor='#26de81'
                  onClick={() => {
                    collectionTimeTimes.push({
                      startTime: 1020,
                      endTime: 1320
                    })
                    reallyOnChangeTime()
                  }}
                >
                  Time
                </Button>
              </div>
            </Form>
          </Box>
        </div>
      )}
    </Box>
  )
}

const AppearanceStuff = [
  {
    id: 'application-blocks',
    Render: function DoRender ({ context, extraProps }) {
      return <StyledPa>
        <div className='grid'>
          <Box><PartProduct context={context} /></Box>
          <PartProductCategories context={context} />
          <PartQuestions context={context} extraProps={extraProps} />
          <PartFlowType context={context} />
        </div>
      </StyledPa>
    }
  },
  {
    id: 'stickyretail-product',
    Render: function DoRender ({ context, extraProps }) {
      const { state: { application }, onUpdate } = context
      return <StyledPa>
        <div className='grid'>
          <Box>
            <PartProduct context={context} />
            <Switch
              checked={application.stickyretail.readFrom('autoAddToCart') || false}
              onChange={v => {
                application.stickyretail.writeTo('autoAddToCart', v)
                onUpdate({ 'stickyretail': application.stickyretail })
              }}
            >
              Auto add to bag
            </Switch>
          </Box>
          <PartQuestions context={context} extraProps={extraProps} />
          <PartFlowType context={context} />
        </div>
      </StyledPa>
    }
  },
  {
    id: 'stickypay',
    Render: function DoRender ({ context, extraProps }) {
      return <StyledPa>
        <div className='grid'>
          <PartQuestions context={context} extraProps={extraProps} />
          <PartFlowType context={context} />
        </div>
      </StyledPa>
    }
  }
]

export default AppearanceStuff
